import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
  const [stickyClass, setStickyClass] = useState("relative");
  const homeNav = () => {
    if (document.body.classList.contains("home-sm")) {
      document.body.classList.remove("home-sm");
    } else {
      document.body.classList.add("home-sm");
    }
  };

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 50
        ? setStickyClass("bg-transparent")
        : setStickyClass("bg-black");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg transition-all`}
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src="assets/logo.svg" height={35} alt="" />
          </Link>
          <div className="d-md-none d-inline-flex gap-2 align-items-center">
            <button  className="navbar-toggler text-black" type="button" onClick={() => {homeNav();}}>
              <span className="iconify" data-icon="humbleicons:bars"></span>
            </button>
          </div>

          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" >
            <div className="d-md-none d-flex justify-content-end text-end p-4">
              
              <a href="javascript:void(0)"  onClick={() => {homeNav();}} className="cross-btn text-black">
                <span className="iconify" data-icon="akar-icons:cross"></span>
              </a>
            </div>
            <ul className="navbar-nav align-items-center mx-auto px-4 px-lg-0">
              <li className="nav-item">
                <a href="#home"  className="nav-link"  onClick={() => {homeNav();}}>
                  About
                </a>
              </li>
              <li className="nav-item">
                <a href="#advantages" className="nav-link"  onClick={() => {homeNav();}}>
                  Docs
                </a>
              </li>
              <li className="nav-item">
                <a href="javascript:void(0)"  className="nav-link" onClick={() => {homeNav();}}  rel="noreferrer">
                  Developers
                </a>
              </li>
              <li className="nav-item">
                <a href="javascript:void(0)"  className="nav-link"  onClick={() => {homeNav();}}>
                  Ecosystem
                </a>
              </li>
              
            </ul>
            <div className="d-lg-flex gap-5 px-4 px-lg-0 position-relative">
              <a href="https://helixnetwork-explorer.alt.technology/" rel="noreferrer" target={"_blank"} className="primary-btn py-2 h-auto"> Launch Testnet  </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
