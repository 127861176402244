import React, { useEffect } from "react";
import { WindupChildren,Pace } from "windups";

import Aos from "aos";
import "aos/dist/aos.css";


const Home = () => {
 

  useEffect(function () {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      {/* Banner  */}
      <section className="banner" id="home">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 z-1" data-aos="fade-up">
              <h1>Hyperscale <br /> Layer 2 Chain</h1>
              
              <p className="my-4 py-lg-4">
                Optimistic rollup combined with decentralized data availability for low fees & rapid finality.
              </p>
              <p className="mb-4 pb-lg-4">
              Powered by Eigen Layer <img src="assets/eigen.svg" alt="" />
              </p>
              <div className="d-md-flex gap-4 mt-md-0 mt-5">
                <a href="javascript:void(0)" className="primary-btn mb-md-0 mb-4">
                  Build on Helix Testnet 
                </a>
                <a href="javascript:void(0)" className="primary-btn-outline">
                Join Helix Community
                </a>
              </div>
            </div>
            <div className="col-md-6 offset-md-1 d-md-block d-none">
            <img src="assets/banner.png" className="w-100" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="bg-black py-5">
        <div className="container py-md-5">
          <div className="row align-items-center py-md-5">
            <div className="col-md-5 text-center d-md-block d-none">
              <img src="assets/one-entry.png" alt="" />
            </div>
            <div className="col-md-6 offset-md-1">
                    <h2 className="text-white mb-5">One entry point into all of crypto</h2>
                    <p className="text-white mb-4">Crypto today is insular and siloed. Helix Network believes that crypto should be easy,
                       safe, and accessible for everyone. Just like the Internet, you shouldn’t have to know how
                       it all works in order to use and benefit from it.</p>

                       <p className="text-white mb-4">Apps built on Helix Network connect to anything in the crypto ecosystem, giving you simple,
                         seamless, and secure access to all of crypto, as if it were all in a single place.</p>

                         <div className="d-flex gap-4">
                <a href="javascript:void(0)" className="primary-btn-outline">
                For developers →
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="bg-light1 py-md-5">
        <div className="container py-md-5">
          <div className="row align-items-center py-5">
            <div className="col-md-9">
                <h2 className="mb-4">Alignment with Ethereum's Vision</h2>
                <p className="mb-md-3 mb-5">Building a hyperscale layer 2 ecosystem while utilizing the benefits of Ethereum layer 1 security.</p>
            </div>
            <div className="col-md-3 text-md-end">
            <a href="javascript:void(0)" className="primary-btn d-inline-flex">
            Documentation →
                </a>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="vision-card">
                <div className="v-img mb-5">
                  <img src="assets/door-handle.svg" alt="" />
                </div>
                <h4>Hyperscale Performance</h4>
                <ul>
                <li>Low fees and rapid transaction finality</li>
                <li>Integration of roll-up technology (OPStack)</li>
                <li>Decentralized data availability powered by EigenDA technology</li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="vision-card">
                <div className="v-img mb-4">
                  <img src="assets/code.svg" alt="" />
                </div>
                <h4 className="pt-1">Secured by <br className="d-lg-block d-none" /> Ethereum</h4>
                <ul>
                <li>High-throughput capabilities</li>
                <li>Unrivaled security via Ethereum's roll-up technology</li>
                <li>Familiar EVM environment</li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="vision-card">
                <div className="v-img mb-5">
                  <img src="assets/lock.svg" alt="" />
                </div>
                <h4>Helix Network <br className="d-lg-block d-none" /> Mainnet</h4>
                <ul>
                <li>Exceptional UX for dApp development</li>
                <li>Unmatched Ethereum security assurance</li>
                <li>High-performance layer-2 network</li>
                <li>Modular architecture design</li>
                </ul>
              </div>
            </div>
          </div>


          <div className="row align-items-center py-5 mt-lg-5">
            <div className="col-md-8">
                <h2 className="mb-4">Building an Ecosystem</h2>
                <p className="mb-md-3 mb-5">Our vision is to build an entire ecosystem on top of our layer 2. This will enable a variety of use cases 
                  including DeFi, NFTs, Metaverse, Gaming, Identity, Social, and more. Additionally, there is a strong focus 
                  on interoperability between other blockchains.</p>
            </div>
            <div className="col-md-4 text-md-end">
            <a href="javascript:void(0)" className="primary-btn d-inline-flex">
            Dive deeper →
                </a>
            </div>

            <div className="col-md-12 pt-5 d-md-block d-none">
              <img src="assets/built.png" className="w-100" alt="" />
            </div>
          </div>

        </div>
      </div>


      <div className="bg-black py-md-5">
        <section>
        <div className="container py-md-5">
          <div className="row align-items-center py-5">
            <div className="col-md-6">
                    <h2 className="text-white mb-5">Get Started</h2>
                    <p className="text-white mb-4">Add chain to Metamask and request faucet funds to get started with sending transactions, 
                    deploying your app, and more.</p>

                         <div className="d-flex gap-4">
                <a href="javascript:void(0)" className="primary-btn-outline">
                Add Chain & Request Test Tokens →
                </a>
              </div>
            </div>

            <div className="col-md-5 text-center offset-md-1 d-md-block d-none">
              <img src="assets/started.png" className="w-100" alt="" />
            </div>
          </div>


          <div className="row align-items-center py-5 mt-lg-5">
            <div className="col-md-12">
                <h2 className="mb-4 text-white d-md-flex d-none align-items-center justify-content-center gap-2">Helix Network for
                <div > <span className="text-gradient"> DeFi </span><br /> <span className="text-yellow"> PAYMENTS </span>
                 <br /> <span className="text-gradient1">Identity</span></div> </h2>
                <h2 className="mb-4 d-block d-md-none text-white">Use case </h2>

                
            </div>
            <div className="col-md-12 py-5 d-md-block d-none">
              <img src="assets/payment.png" className="w-100" alt="" />
            </div>

            <div className="col-md-12 d-block d-md-none">
              <div className="use-card bg-1">
                      <h4 className="mb-0">DeFi</h4>
                      <img src="assets/u1.svg" alt="" />
              </div>

              <div className="use-card bg-2">
                      <h4 className="mb-0">NFT</h4>
                      <img src="assets/u2.svg" alt="" />
              </div>

              <div className="use-card bg-3">
                      <h4 className="mb-0">Identity</h4>
                      <img src="assets/u3.svg" alt="" />
              </div>

              <div className="use-card bg-4">
                      <h4 className="mb-0 text-black">Social</h4>
                      <img src="assets/u4.svg" alt="" />
              </div>

              <div className="use-card bg-white">
                      <h4 className="mb-0 text-black">Media</h4>
                      <img src="assets/u5.svg" alt="" />
              </div>
            </div>

            <div className="col-md-12">
              <h2 className="text-white my-5">Interested in building with us? Chat with an expert to start building your integration.</h2>

              <a href="javascript:void(0)" className="primary-btn-outline d-inline-flex">
            Contact →
                </a>
            </div>
          </div>

        </div>
        </section>
      </div>


     <div className="bg-light1">
      <div className="container">
      <div className="row align-items-center py-5">
            <div className="col-md-8">
                <h2 className="mb-4">Partners</h2>
                <p className="mb-md-3 mb-5">Explore Helix Network's large and growing ecosystem of diverse apps and partners across DeFi, 
                  Infrastructure, Wallets, Social, and beyond. See who is building with Helix Network towards 
                  the future of connected crypto.</p>
            </div>
            <div className="col-md-4 text-md-end">
            <a href="javascript:void(0)" className="primary-btn d-inline-flex">
            See all parthners →
                </a>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="vision-card p-3 text-center">
                <img src="assets/p1.png" alt="" />
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="vision-card p-3 text-center">
                <img src="assets/p2.png" alt="" />
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="vision-card p-3 text-center">
                <img src="assets/p3.png" alt="" />
              </div>
            </div>

          </div>
      </div>
     <section className="faq py-5">
        <div className="container">
          <div className="row justify-content-center py-5">
            <div className="col-md-10">
              <h2 className="mb-4">FAQ</h2>

              <div className="box-faq">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h3 className="accordion-header" id="headingOne">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                        aria-expanded="false" aria-controls="collapseOne">
                       What is Helix Network, in simple terms & why does it matter?
                      </button>
                    </h3>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Helix network is Hyperscale Layer 2 Optimistic Rollup Powered by OP Stack  combined with decentralized data  availability for low fee and rapid finality.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading2">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2"
                        aria-expanded="false" aria-controls="collapse2">
                       Why was Helix Network created?
                      </button>
                    </h3>
                    <div id="collapse2" className="accordion-collapse collapse"  aria-labelledby="heading2" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Helix Network envisions a crypto landscape where accessibility mirrors that of internet, benefitting a broader audience. Helix Network was conceived to offer a more secure, cost-efficient, adaptable and seamless solution, with the goal of all being fairly compensated for their contributions to the ecosystem, whether through referrals or revenue secured by contracts.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading3">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3"
                        aria-expanded="false" aria-controls="collapse3">
                       How does Helix Network work?
                      </button>
                    </h3>
                    <div id="collapse3" className="accordion-collapse collapse"  aria-labelledby="heading3" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      The Helix Network is built using Optimism's OP Stack, empowering accelerated application development and EVM-compatible application deployment. Notably, Helix Network boasts network fees that are significantly lower than those on the Ethereum Mainnet.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading4">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4"
                        aria-expanded="false" aria-controls="collapse4">
                      How secure is Helix Network?
                      </button>
                    </h3>
                    <div id="collapse4" className="accordion-collapse collapse"  aria-labelledby="heading4" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Helix Network employs a straightforward and highly dependable trust system, wherein only our network is relied upon to transmit data and value, eliminating the need for third-party intermediaries. Helix Network has the capability to securely manage and safeguard assets without exposing them to risk while they are dormant.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading5">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5"
                        aria-expanded="false" aria-controls="collapse5">
                       What’s unique about Helix Network?
                      </button>
                    </h3>
                    <div id="collapse5" className="accordion-collapse collapse"  aria-labelledby="heading5" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      The Hyperscale Performance offered by the Helix Network with low transaction fees and rapid finality with an integration of roll-up technology makes it unique. This platform also boasts decentralized data availability powered by EigenDA technology and is securely backed by Ethereum's high-throughput capabilities. Users can expect an exceptional user experience for dApp development on this high-performance layer-2 network, which features a modular architecture design while maintaining the familiar EVM environment, ensuring unmatched security and efficiency.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading6">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6"
                        aria-expanded="false" aria-controls="collapse6">
                       What does the future look with Helix Network?
                      </button>
                    </h3>
                    <div id="collapse6" className="accordion-collapse collapse"  aria-labelledby="heading6" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      We envision the future of web3 as a connected realm where users can seamlessly connect with the Helix Network ecosystem and harness the capabilities of multiple networks, and users won't have to be concerned about transitioning or connecting between them. Helix Network will act as an invisible driving force, ensuring a smooth user experience for all..
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading7">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7"
                        aria-expanded="false" aria-controls="collapse7">
                      Where can I learn more about Helix Network?
                      </button>
                    </h3>
                    <div id="collapse7" className="accordion-collapse collapse"  aria-labelledby="heading7" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Check out our more technical FAQs here and glossary here.  Docs Link .
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading8">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8"
                        aria-expanded="false" aria-controls="collapse8">
                      How will Helix network benefit for you?
                      </button>
                    </h3>
                    <div id="collapse8" className="accordion-collapse collapse"  aria-labelledby="heading8" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Helix Network is secured by Ethereum with Low fees and rapid transaction finality.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading9">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9"
                        aria-expanded="false" aria-controls="collapse9">
                       How can I stay connected with the Helix Network Community?
                      </button>
                    </h3>
                    <div id="collapse9" className="accordion-collapse collapse"  aria-labelledby="heading9" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      To stay connected with the Helix Network community, follow official Helix social handle participate in Helix-based forums like the Helix Discord, attend virtual or physical Helix meetups and conferences, and engage in Helix-focused developer communities. This will help you stay updated and network with like-minded individuals.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading10">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse10"
                        aria-expanded="false" aria-controls="collapse10">
                       Do I need a crypto wallet to use Helix Network?
                      </button>
                    </h3>
                    <div id="collapse10" className="accordion-collapse collapse"  aria-labelledby="heading10" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      Certainly! If you're interested in using Helix Network and engaging with numerous projects, the initial requirement is to establish a Web3 wallet. This will enable you to engage with the Helix Network platform. A widely favored wallet option is MetaMask, which is accessible as a browser extension or a mobile application.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading11">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse11"
                        aria-expanded="false" aria-controls="collapse11">
                      Does Helix Network have a native token?
                      </button>
                    </h3>
                    <div id="collapse11" className="accordion-collapse collapse"  aria-labelledby="heading11" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      TBA
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h3 className="accordion-header" id="heading12">
                      <button  className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse12"
                        aria-expanded="false" aria-controls="collapse12">
                       Where can I buy the Helix Network Token?
                      </button>
                    </h3>
                    <div id="collapse12" className="accordion-collapse collapse"  aria-labelledby="heading12" data-bs-parent="#accordionExample">
                      <div className="accordion-body">
                      TBA
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     </div>


    </>
  );
};

export default Home;
