import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container pt-5">
          <div className="row justify-content-between align-items-center mb-4">
            <div className="col-md-2">
              <div className="footer-logo mb-4">
                <Link to="/">
                  <img src="assets/footer-logo.svg" height={35} alt="" />
                </Link>
              </div>
            </div>
          </div>

          <div className="row justify-content-between mb-4">
            <div className="col-md-3 col-6 mb-4">
              <h5 className="text-yellow text-uppercase mb-4">About</h5>
              <ul>
                <li><a href="javascript:void(0)">Blog</a></li>
                <li><a href="javascript:void(0)">Press Kit</a></li>
                <li><a href="javascript:void(0)">Get in touch</a></li>
                <li><a href="javascript:void(0)">Feedback</a></li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mb-4">
              <h5 className="text-yellow text-uppercase mb-4">Community</h5>
              <ul>
                <li><a href="https://discord.gg/b9y6WaSVst" target={"_blank"} rel="noreferrer">Discord</a></li>
                <li><a href="https://twitter.com/helixnetworkio" target={"_blank"} rel="noreferrer">Twitter</a></li>
                <li><a href="javascript:void(0)">Zealy</a></li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mb-4">
              <h5 className="text-yellow text-uppercase mb-4">Developers</h5>
              <ul>
                <li><a href="https://helix-network.gitbook.io/helix-network/" target={"_blank"} rel="noreferrer">Docs</a></li>
                <li><a href="javascript:void(0)">Github</a></li>
                <li><a href="javascript:void(0)">Grants</a></li>
              </ul>
            </div>

            <div className="col-md-3 col-6 mb-4">
              <h5 className="text-yellow text-uppercase mb-4">LEGAL</h5>
              <ul>
                <li><a href="javascript:void(0)">Privacy Policy</a></li>
                <li><a href="javascript:void(0)">Terms of Use</a></li>
                <li><a href="javascript:void(0)">Testnet Terms</a></li>
              </ul>
            </div>

            <div className="col-md-12 py-2">
              <p className="opacity-50 text-white text-center text-md-start">© 2023 Helix Network</p>
            </div>
          </div>
         
        </div>
      </footer>
    </>
  );
};

export default Footer;
